// Import bootstrap plugins
import * as bootstrap from 'bootstrap';
import jQuery from 'jquery';
import "@accessible360/accessible-slick";
import Player from '@Vimeo/player';

// onready
(function($) {

	//functions
	//Base init
	function baseInIt() {
		//Get the to top button:
		topButton = document.getElementById("topButton");
		header = document.getElementById("header");

		// When the user scrolls down 20px from the top of the document, show the button
		window.onscroll = function() {scrollFunction()};

		function scrollFunction() {
			if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
				topButton.style.display = "block";
				header.classList.add("scrolling");
			} else {
				topButton.style.display = "none";
				header.classList.remove("scrolling");
			}
		}

		// When the user clicks on the to top button, scroll to the top of the document
		topButton.addEventListener('click', function(e) {
			e.preventDefault();
			document.body.scrollTop = 0; // For Safari
			document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		});
	}

	//Form - Get Started Functions
	function getStartedInit() {
		var warning1 = document.getElementById("warning1");
		var radios1 = document.querySelectorAll('input[type=radio][name="position"]');
		radios1.forEach(radio => radio.addEventListener('change', () => warning1.classList.add("d-none")));
		var continue1 = document.getElementById("continue1");
		var back1 = document.getElementById("back1");
		var warning2 = document.getElementById("warning2");
		var radios2 = document.querySelectorAll('input[type=radio][name="production"]');
		radios2.forEach(radio => radio.addEventListener('change', () => warning2.classList.add("d-none")));
		var continue2 = document.getElementById("continue2");
		var back2 = document.getElementById("back2");
		var nav1 = document.getElementsByClassName("nav1");
		var nav2 = document.getElementsByClassName("nav2");
		var nav3 = document.getElementsByClassName("nav3");

		back1.addEventListener('click', gotoone);
		back2.addEventListener('click', gototwo);
		continue1.addEventListener('click', gototwo);
		continue2.addEventListener('click', gotothree);

		for (var i = 0; i < nav1.length; i++) {
			nav1[i].addEventListener('click', gotoone);
		}

		for (var i = 0; i < nav2.length; i++) {
			nav2[i].addEventListener('click', gototwo);
		}

		for (var i = 0; i < nav3.length; i++) {
			nav3[i].addEventListener('click', gotothree);
		}

		jQuery('#submit').click(function (e) {
			console.log(e);
	        e.preventDefault();
	        var el = $(this);
	        el.prop('disabled', true);
	        setTimeout(function(){el.prop('disabled', false); }, 3000);
	        console.log('Submit Clicked');
	        requiredFields();
		});

		function gotoone(e) {
			e.preventDefault();

	        // Select div element by its id attribute
	        var form1 = document.getElementById("form1");
	        var form2 = document.getElementById("form2");
	        var form3 = document.getElementById("form3");
	        if(form3.classList.contains("animate__zoomIn")) {
	        	form3.classList.remove("animate__zoomIn");
	        }
	        form3.classList.add("animate__zoomOut");  // Add zoomout class
	        if(form2.classList.contains("animate__zoomIn")) {
	        	form2.classList.remove("animate__zoomIn");
	        }
	        form2.classList.add("animate__zoomOut");
	        if(form1.classList.contains("animate__zoomOut")) {
	        	form1.classList.remove("animate__zoomOut");
	        }
	        form1.classList.add("animate__zoomIn");  // Add zoomout class

	        setTimeout(() => {
	        	form2.classList.add("d-none");
	        	form3.classList.add("d-none");
	        	form1.classList.remove("d-none");
	        }, 200);
		}

		function gototwo(e) {
			e.preventDefault();

		    var category = document.getElementsByName("position");
		    var check1 = 0;
		    for(i=0;i<category.length;i++){
		        if(category[i].checked){
		            check1++;
		            break;
		        }
		    } if (check1) {
		        // Select div element by its id attribute
		        var form1 = document.getElementById("form1");
		        var form2 = document.getElementById("form2");
		        var form3 = document.getElementById("form3");
		        if(form1.classList.contains("animate__zoomIn")) {
		        	form1.classList.remove("animate__zoomIn");
		        }
		        form1.classList.add("animate__zoomOut");  // Add zoomout class
		        if(form3.classList.contains("animate__zoomIn")) {
		        	form3.classList.remove("animate__zoomIn");
		        }
		        form3.classList.add("animate__zoomOut");  // Add zoomout class
		        if(form2.classList.contains("animate__zoomOut")) {
		        	form2.classList.remove("animate__zoomOut");
		        }
		        form2.classList.add("animate__zoomIn");
		        setTimeout(() => {
		        	form1.classList.add("d-none");
		        	form3.classList.add("d-none");
		        	form2.classList.remove("d-none");
		        }, 200);
		    } else {
		        var warning1 = document.getElementById("warning1");
		        warning1.classList.remove("d-none");
		        return false;
		    }
		}

		function gotothree(e) {
			e.preventDefault();

		    var category = document.getElementsByName("production");
		    var check2 = 0;
		    for(i=0;i<category.length;i++){
		        if(category[i].checked){
		            check2++;
		            break;
		        }
		    } if (check2) {
		        // Select div element by its id attribute
		        var form1 = document.getElementById("form1");
		        var form2 = document.getElementById("form2");
		        var form3 = document.getElementById("form3");
		        if(form1.classList.contains("animate__zoomIn")) {
		        	form1.classList.remove("animate__zoomIn");
		        }
		        form1.classList.add("animate__zoomOut");  // Add zoomout class
		        if(form2.classList.contains("animate__zoomIn")) {
		        	form2.classList.remove("animate__zoomIn");
		        }
		        form2.classList.add("animate__zoomOut");
		        if(form3.classList.contains("animate__zoomOut")) {
		        	form3.classList.remove("animate__zoomOut");
		        }
		        form3.classList.add("animate__zoomIn");  // Add zoomout class
		        setTimeout(() => {
		        	form1.classList.add("d-none");
		        	form2.classList.add("d-none");
		        	form3.classList.remove("d-none");
		        }, 200);
		    } else {
		        var warning2 = document.getElementById("warning2");
		        warning2.classList.remove("d-none");
		        return false;
		    }
		}

		function requiredFields(){
	        var proceed = false;
	        var position = jQuery('input[name="position"]:checked').val();
	        var production = jQuery('input[name="production"]:checked').val();
	        var firstName = jQuery('#firstName');
	        var lastName = jQuery('#lastName');
	        var zip = jQuery('#zip');
	        var email = jQuery('#email');
	        var phone = jQuery('#phone');
	        if (! firstName[0].checkValidity()) {
				jQuery('#firstName').addClass('red-box');
	        } else {
				jQuery('#firstName').removeClass('red-box');
	        }
	        if (! lastName[0].checkValidity()) {
	            jQuery('#lastName').addClass('red-box');
			} else {
	            jQuery('#lastName').removeClass('red-box');
			}
	        if (! zip[0].checkValidity()) {
				jQuery('#zip').addClass('red-box');
	        } else {
				jQuery('#zip').removeClass('red-box');
	        }
	        if (! email[0].checkValidity()) {
				jQuery('#email').addClass('red-box');
	        } else {
				jQuery('#email').removeClass('red-box');
	        }
	        if (! phone[0].checkValidity()) {
				jQuery('#phone').addClass('red-box');
	        } else {
				jQuery('#phone').removeClass('red-box');
	        }
	        if (jQuery("#form-start")[0].checkValidity()) {
				proceed = true;
	        } else {
				proceed = false;
	        } if (proceed) {
				var body = {};
				body.FirstName = $('#firstName').val();
				body.LastName = $('#lastName').val();
				body.LearnMoreOther = `${position} ${production}`;
				body.Email = $('#email').val();
				body.Zip = $('#zip').val();
				body.Phone = $('#phone').val();
				body.NMLS = 222222;  //Change
				body.CampaignID = 4277;  //Change
				var jsonBody = JSON.stringify(body);
				console.log(jsonBody);
				//Logic to send post to optifi here
				jQuery.ajax({
					method: "POST",
					headers: {
						'CONTENT-TYPE': 'application/json',
						'AUTHORIZATION': 'APIAuth openmtgleads:Y6L0Bmx5FewRRad6SaQN',
		            },
		            url: "https://w8xqlxtif4.execute-api.us-west-2.amazonaws.com/production/contactForm",
					dataType: 'json',
					data: jsonBody,
					processData: false,
					success(data) {
		                console.log('success', jsonBody);
		                console.log(data);
		                var FirstName = $('#firstName').val();
		                console.log(FirstName);
		                $("#shoutName").text(FirstName);
		                var form3 = document.getElementById("form3");
		                var form4 = document.getElementById("form4");
		                form3.classList.remove("animate__zoomIn");  // Add zoomout class
		                form3.classList.add("animate__zoomOut");  // Add zoomout class
		                form4.classList.add("animate__zoomIn");
		                setTimeout(() => {  form3.classList.add("d-none"); },200);
		                setTimeout(() => {  form4.classList.remove("d-none"); },200);  
					},
					error: function (err) {
		                console.log('error', err);
					}
	            });
	            return false;
	        }
	    }
	}

	//Content - Videos Slider Functions
	function videosSliderInIt() {

		// variables
		var videoModals = $(".video-slider-modal");

        // slick slider
		$(".video-slider").slick({
	        dots: true,
			infinite: true,
			slidesToShow: 2,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		// video slides functionality
		videoModals.each(function() {
			var modal = $(this);
			var iframe = modal.find("iframe");
			var player = new Vimeo.Player(iframe[0]);

			modal[0].addEventListener('show.bs.modal', function(e) {
				player.play();
			});

			modal[0].addEventListener('hide.bs.modal', function(e) {
				player.unload();
			});
		});
	}

	//Content - Videos Tab Slider Functions
	function videosTabSliderInIt(section) {

		// variables
		var videoModals = $(".video-tab-slider-modal");

		// slick slider functions
		function slickSlider(contentTabId) {
			var sliderClass = '#' + contentTabId + ' .video-tab-slider';

			var dum = section.getElementsByClassName('tab-pane');
			for(var i = 0; i < dum.length; i++) {
				if(dum[i].id != contentTabId) {
					dum[i].style.display = "none";
				}
			}
	
			$('.video-tab-slider.slick-slider').slick('unslick');

			$(sliderClass).slick({
				dots: true,
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
			});
		}

		// init first slider
		$(".tab-pane.active .video-tab-slider").slick({
			dots: true,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		});

		// onclick, reslick, and unslick
		var tabButtons = section.querySelectorAll('.nav-link');

		for (var i = 0; i < tabButtons.length; i++) {
			tabButtons[i].addEventListener('click', function(e) {
				var tabId = e.target.getAttribute('aria-controls');

				document.getElementById(tabId).style.display = 'block';
	
				slickSlider(tabId);
			});
		}

		// video slides functionality
		videoModals.each(function() {
			var modal = $(this);
			var iframe = modal.find("iframe");
			var player = new Vimeo.Player(iframe[0]);

			modal[0].addEventListener('show.bs.modal', function(e) {
				player.play();
			});

			modal[0].addEventListener('hide.bs.modal', function(e) {
				player.unload();
			});
		});
	}

	//Content - Press Slider Functions
	function pressSliderInIt(section) {

		// slick slider
		$(".press-slider").slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		    mobileFirst: true,
		    adaptiveHeight: true,
			responsive: [
				{
					breakpoint: 992,
					settings: "unslick"
				}
			]
		});

		$(window).resize(function() {
			if($(window).innerWidth() > 991) {
				$('.press-slider.slick-initialized').slick('unslick');
			} else if($(window).innerWidth() < 992) {
				$('.press-slider:not(.slick-initialized)').slick({
					infinite: true,
					slidesToShow: 1,
					slidesToScroll: 1,
				    mobileFirst: true,
				    adaptiveHeight: true
				});
			}
		});
	}

	// Header - Half Content / Half Video Functions
	function headerVideoInit() {
		var body = document.body;

		if(!body.classList.contains('header-overflow')) {
			body.classList.add('header-overflow');
		}
	}
	
	//Base Init
	baseInIt();

	//Header - Half Content / Half Video JS
	if(document.getElementsByClassName("header-half-content-half-video").length) {
		headerVideoInit();
	}

	//Form - Get Started JS
	if(document.querySelectorAll(".form-get-started").length) {
		getStartedInit();
	}

	//Content - Videos Slider JS
	if(document.getElementsByClassName("content-videos-slider").length) {
		videosSliderInIt();
	}

	// Content - Videos Tabs JS
	if(document.getElementsByClassName("content-video-tabs").length) {
		var sections = document.getElementsByClassName("content-video-tabs");
		for (var i=0; i < sections.length; i++) {
			videosTabSliderInIt(sections[i]);
		}
	}

	//Related - Press JS
	if(document.getElementsByClassName("related-press").length) {
		var sections = document.getElementsByClassName("related-press");
		for (var i = 0; i < sections.length; i++) {
			pressSliderInIt(sections[i]);
		}
	}
})(jQuery);